.spanMargin {
  margin-bottom: 5px;
  display: block;
}

.btnCol {
  align-self: flex-end;
}

.paddingBetweenRows :global(.ant-col) {
  margin-bottom: 24px;
}
