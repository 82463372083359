.setupRepeatModal :global(.ant-form-item) {
  margin-bottom: 0;
}

.repeatOnContentWrapper {
  margin-bottom: 30px;
}

.repeatEveryContentWrapper,
.endsOnContentWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.repeatEveryContentWrapper :global(.ant-form-item) {
  margin-bottom: 0;
}

.repeatEveryText,
.repeatEveryNumberWrapper,
.endsOnText {
  margin-right: 5%;
}

.textSpan {
  font-size: 1.1em;
  font-weight: 500;
}

.repeatEveryPeriodWrapper {
  width: 85px;
}

.dayCheckboxesWrapper :global(.ant-checkbox-group) {
  display: flex;
  flex-wrap: wrap;
}

.dayCheckboxesWrapper :global(.ant-checkbox-group) > label {
  width: 33.33%;
  margin-top: 10px;
}

.dayCheckboxesWrapper :global(.ant-checkbox-group-item) {
  margin-right: 0;
}

/* .dayCheckboxesWrapper > div {
  width: 33.33%;
  margin-top: 10px;
} */
