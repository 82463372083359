.footer {
  display: flex;
  justify-content: space-between;
}

.footerRight {
  display: flex;
  gap: 10px;
}

.actionBtns {
  display: flex;
  align-items: center;
  gap: 10px;
}

.macroDropdown :global(.ant-dropdown-menu) {
  max-height: 80vh;
  overflow: auto;
}

/* .actionBtns button {
  width: 32px;
  height: 32px;
} */

:global(.row-dragging) {
  background: #fafafa;
  border: 1px solid #ccc;
}

:global(.row-dragging td) {
  padding: 16px;
}

:global(.row-dragging .drag-visible) {
  visibility: visible;
}

.formListHeaderRow :global(.ant-col) {
  word-wrap: normal;
}
