.pixelContent {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.perPixelRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.perPixelLabel {
  font-weight: 500;
}

.pixelFooter {
  /* border-top: 1px solid rgb(231, 231, 231); */
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
