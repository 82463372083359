.previewModal :global(.ant-modal-header){
  border-bottom: none;
}

.previewModal :global(.ant-modal-body){
  padding-top: 0;
  padding-bottom: 0;
}

.previewModal :global(.ant-modal-footer){
  border-top: none;
}

.previewModal :global(.ant-modal-footer .ant-btn:first-child){
  color: #40a9ff;
  border-color: #40a9ff;
}

.previewModal h3 {
  font-size: 14px;
  font-weight: 600;
}

.previewModal .desc,
.previewModal .type {
  margin-bottom: 1rem;
}

.previewModal .type img{
 margin-right: .3rem;
}
