.importBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 1px solid #1890ff;
  background-color: #fff;
  color: #1890ff;
  border-radius: 50%;
  cursor: pointer;
}

.importBtnWrapper:hover {
  background-color: #1890ff;
  color: #fff;
}
