.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions > div {
  cursor: pointer;
  margin-right: 10px;
}

.actions div:last-child {
  margin-right: 0;
}

.actions :global(.ant-popover-inner-content) {
  padding: 5px 8px !important;
}
