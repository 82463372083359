.targetingFormFieldsWrapper {
  display: flex;
  align-items: flex-end;
}

.targetingFormFieldsWrapper > div {
  margin-right: 3%;
}

.formItemWrapper3 {
  width: 30%;
}

/* .formItemWrapper4 {
  width: 22%;
} */

.patternFormListWrapper :global(.ant-btn) {
  height: 28px;
  padding: 2px 15px;
}

.disabledForm {
  cursor: not-allowed;
  opacity: 0.5;
  background: #ccc;
  color: rgba(0, 0, 0, 0.65);
  padding: 10px;
}

.disabledForm div {
  pointer-events: none;
}

.disabledForm :global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector),
.disabledForm :global(.ant-btn-dashed),
.disabledForm :global(.ant-btn) {
  background: rgb(241, 241, 241);
  color: rgba(0, 0, 0, 0.65);
}

.disabledForm .targetingAddPattern :global(.ant-form-item) {
  margin-bottom: 17px;
}

.selectAllPatternsAndDeleteSelectedWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
  position: sticky;
  position: -webkit-sticky;
  top: 50px;
  background-color: white;
  z-index: 3;
}

.selectAllPatternsCheckbox {
  margin: 0 20px 0 10px;
}

.deleteSelectedBtn {
  margin-right: 10px;
}

.targetingColumnTitlesWrapper {
  display: flex;
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 4px;
  padding: 2px 0;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
}

.patternSelectAndMoverWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
}

.targetingPatternWrapper :global(.ant-form-item-label) {
  padding: 0;
}

.targetingPatternWrapper :global(.ant-form-item-label > label) {
  height: 20px;
  font-size: 0.9em;
}

.patternSelect {
  width: 8%;
  font-size: 1.1em;
  font-weight: 500;
}

.patternSelect :global(.ant-form-item) {
  margin-bottom: 0;
}

.notParrentColWrapper {
  display: flex;
}

.notParrentColWrapper :global(.ant-input-number) {
  width: 78px;
}

.typeCol,
.productCol,
.landingPageCol,
.s2sCol,
.s2sColTags,
.s2sAdvanced,
.externalUrlCol {
  padding-right: 10px;
}

.typeCol {
  width: 9%;
}

.productCol {
  width: 15%;
}

.landingPageCol {
  width: 15%;
}

.externalUrlCol {
  width: 12%;
}

.automationRulesCol {
  width: 6%;
}

.optimiseBySourceCol {
  width: 7%;
}

/*
  S2S column styles
*/

.s2sTagsContainer {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 4px 10px;
  border-radius: 2px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  padding-right: 30px;
  user-select: none;
  text-transform: uppercase;
}

.selectColPixels {
  position: relative;
  width: 100%;
}

.s2sColEdit {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1890ff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  cursor: pointer;
  color: #fff;
  width: 30px;
  border: 1px solid #d9d9d9;
  top: 0;
  right: 0;
  height: 100%;
}

.s2sHigher {
  min-width: 250px;
  width: 19%;
}

.s2sHigherHeader {
  display: flex;
  flex-direction: column;
  width: 19%;
  min-width: 250px;
}

.s2sSubHeader {
  display: flex;
  font-size: 0.8rem;
}

.s2sAdvanced {
  display: flex;
  flex-direction: row;
  min-width: 75px;
  align-items: center;
  width: 30%;
}

.s2sCol {
  display: flex;
  /* width: 14%; */
  width: 100%;
  min-width: 175px;
}

.s2sColTags {
  display: flex;
  width: 70%;
  min-width: 175px;
}

.pixelTag {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 3px;
  font-size: 0.7rem;
  margin: 2px;
}

/*
  end S2S column styles
*/

.percentageCol {
  width: 9%;
}

.gpssRuleIdInputWrapper {
  z-index: 2;
}

.gpssRuleIdInputWrapper :global(.ant-input) {
  cursor: pointer;
}

/* .gpssRuleCol, */
.actionsCol {
  display: flex;
  justify-content: end;
  align-items: center;
}

.actionsCol > div {
  display: flex;
  align-items: center;
}

.actionsCol > div:not(:last-child) {
  margin-right: 10px;
}

.targetingPatternWrapper {
  display: flex;
  flex-direction: column;
  padding: 2px 4px;
  border-top: 3px solid #bebebe;
  border-bottom: 3px solid #bebebe;
  margin-bottom: 8px;
  background-color: rgb(245, 249, 253);
  position: relative;
}

.disabledGpssPattern {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: not-allowed;
  background-color: rgba(204, 204, 204, 0.3);
  z-index: 2;
}

.gpssPatternWrapper {
  margin-bottom: 0;
  border-bottom: 27px solid #bebebe; /* 24px for removed margin + 3px border */
}

.gpssPatternWrapper :global(.ant-input-number-handler-wrap) {
  display: none;
}

.disabledGpssPattern * {
  pointer-events: none;
}

.patternRowFieldsOuterWrapper {
  position: relative;
  margin-bottom: 5px;
  border-bottom: 1px solid #d9d9d9;
}

.patternRowFieldsInnerWrapper {
  display: 'flex';
  flex-direction: 'column';
  width: 100%;
}

.patternRowFieldsInnerWrapper :global(.ant-form-item),
.notParrentColWrapper :global(.ant-form-item) {
  margin-bottom: 3px;
}

.patternMainContentWrapper {
  width: 100%;
}

.patternSelectsWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.patternSelectsWrapper > div:last-child {
  margin-right: 0;
}

.patternSelectsWrapper :global(.ant-form-item),
.addPatternSetupRow :global(.ant-form-item) {
  margin-bottom: 0;
}

.patternFieldWrapper {
  width: 18%;
  margin-right: 2%;
}

.patternDeviceTypesFieldWrapper {
  width: 40%;
}

.movePatternWrapper {
  display: flex;
  justify-content: flex-end;
  width: 190px;
}

.patternIndex,
.movePattern {
  margin-right: 10px;
  background-color: #fff;
}

.patternIndex,
.movePattern,
.deletePattern {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.patternIndex {
  width: 40px;
}

.patternIndex :global(.ant-input-number-handler-wrap) {
  width: 0px !important;
}

.patternIndex :global(input) {
  text-align: center;
}

.movePattern,
.deletePattern,
.actionsCol > div {
  cursor: pointer;
}

.deletePattern {
  border: none;
  background-color: rgb(219, 64, 64);
  color: #fff;
}

.patternSetupWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.schedulerFieldsWrapper {
  display: flex;
  font-size: 0.9em;
  margin-bottom: 2px;
}

.schedulerFieldsWrapper > div {
  margin-right: 5%;
}

.schedulerFieldsWrapper > div:last-child {
  margin-right: 0;
}

.schedulerFieldsWrapper :global(.ant-form-item) {
  margin-bottom: 0;
}

.schedulerFieldsWrapper :global(.ant-form-item-control-input) {
  min-height: unset;
}

.scheduleRangeAndUTCWrapper,
.setupRepeatButtonAndSpanWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.scheduledRangeFieldWrapper,
.setupRepeatButton {
  margin-right: 10px;
}

.patternSetupRowWrapper .s2sCol > :global(.ant-form-item) {
  width: 100%;
}

.patternSetupRowWrapper div {
  margin-bottom: 0 !important;
}

.patternSetupRowWrapper {
  display: flex;
  padding-bottom: 4px;
  margin-bottom: 2px;
  border-bottom: 1px solid #d9d9d9;
}

.targetingAddDynamicFields,
.patternSetupRowWrapper {
  width: 100%;
}

.patternSetupRowBtnsWrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.addPatternSetupRowWrapper,
.addPatternSetupRow {
  width: 100%;
}

.addPatternSetupRowWrapper :global(.ant-form-item) {
  margin-bottom: 0;
}

.evenlyDistributeAndCloneWrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 380px;
}

.evenlyDistributeBtn,
.clonePatternBtn {
  width: 170px;
}

.evenlyDistributeBtn {
  margin: 0 20px;
}

.percentWarning {
  margin: 20px 0 10px;
  color: rgb(219, 64, 64);
  font-size: 1.1em;
  font-weight: 500;
}

.percentWarning > span {
  margin-right: 5px;
}

.formRemoveBottomMargin :global(.ant-form-item) {
  margin-bottom: 0px;
}

.selectListStyle > div {
  background-color: #c7ddf5 !important;
}

.label {
  font-size: 0.9em;
  height: 20px;
}
