.deleteAligned {
  align-self: center;
  margin-bottom: 24px;
  cursor: pointer;
}

.paddingBottom15 {
  padding-bottom: 15px;
}

.width100 {
  width: 100%;
}

.justifyEnd {
  justify-content: end;
}
