.importExportWrapperInLists,
.importExportWrapperInMCs,
.spinnerCreateModal,
.spinnerEditModal {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.spinnerCreateModal,
.spinnerEditModal {
  justify-content: flex-start;
  margin: 4px 0 24px 8px;
}

.spinnerEditModal {
  justify-content: center;
}

.importBtnWrapper,
.exportBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 1px solid #1890ff;
  background-color: #fff;
  color: #1890ff;
  border-radius: 50%;
  cursor: pointer;
}
