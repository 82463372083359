.campaignNameWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
}

.nameInitialsOnly {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nameInitialsOnly .nameInitials {
  margin-right: 0;
}

.nameInitialsTootltip :global(.ant-tooltip-inner) {
  white-space: pre-line;
  line-height: 2em;
}

.nameInitials {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  min-width: 2.5em;
  min-height: 2.5em;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(85, 85, 85);
  padding-top: 6px;
  margin-right: 5px;
  text-align: center;
}

.checkIconWrapper {
  position: absolute;
  right: -3px;
  bottom: -5px;
}

.checkIconWrapper img {
  width: 15px;
}

.channelName,
.companyName {
  text-align: left;
  word-wrap: break-word;
  word-break: break-all;
}

.channelName {
  font-size: 1.05em;
  color: #000;
}

.companyName {
  font-size: 0.9em;
  color: #36363699;
}
