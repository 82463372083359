.formItemToHorizontal > div:first-child {
  padding: 0;
  margin-left: 10px;
}

.formItemToHorizontal > div {
  align-self: center;
}

.formItemToHorizontal {
  flex-direction: row-reverse !important;
}

.emailValidationMessage {
  margin-bottom: 20px;
  color: #ff4d4f;
}

.collapse :global(.ant-collapse-item .ant-collapse-header) {
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.85);
}

.collapse :global(.ant-collapse-item-disabled .ant-collapse-header .ant-collapse-arrow) {
  display: none;
}

:global(.ant-form-item) {
  margin-bottom: 15px;
}

.platformStatusCol {
  display: flex;
  align-items: center;
}

.lastLogin {
  margin-left: 20px;
}

:global(.ant-collapse-header) {
  display: flex;
  align-items: center;
}

.publisherCategoriesWrapperRow,
.advertiserCategoriesWrapperRow {
  margin-bottom: 10px;
}

.categoryCheckboxFormItem {
  margin-bottom: 5px;
}

.categoryCheckboxFormItem :global(.ant-checkbox-inner),
.categoryCheckboxFormItem :global(.ant-checkbox-checked::after) {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.categoryCheckboxFormItem :global(.ant-checkbox-inner::after) {
  left: 26%;
}

.accountManagerAndSubmanagersWrapperCol {
  margin-left: 29px;
}

.mbCategoriesCheckboxesWrapperCol {
  margin-left: 34px;
}

.twoFaButton:hover {
  border-color: red;
  color: red;
}

.notesInfoField {
  border: none;
  padding: 0;
  pointer-events: none;
}

.notesInfoField :global(.ant-picker-input .ant-picker-suffix) {
  display: none;
}
