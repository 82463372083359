.tagsContainer {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 4px 10px;
  border-radius: 2px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  padding-right: 30px;
  user-select: none;
  text-transform: uppercase;
  height: 100%;
}

.tagsContainerInnerWrapper {
  position: relative;
  width: 100%;
}

.editWrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1890ff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  cursor: pointer;
  color: #fff;
  width: 30px;
  border: 1px solid #d9d9d9;
  top: 0;
  right: 0;
  min-height: 30px;
  height: 100%;
}

.tagsContainerOuterWrapper {
  display: flex;
  width: 70%;
  min-width: 140px;
  min-height: 32px;
}
