.editorModal :global(.ant-modal-content) {
  width: 700px;
  background: #272822;
}

.editorModal :global(.ant-modal-close-x) {
  color: #fff;
}

.hidden {
  display: none;
}

header {
  display: flex;
  height: 65px;
  align-items: center;
  background: #002f6a;
}

header > div:first-child {
  width: 168px;
}

.headerBtn {
  margin-left: 5px;
}

.editorContainer {
  display: flex;
  position: fixed;
  top: 65px;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 99;
}

.expander {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2%;
  height: 100vh;
  background: #272822;
  color: #fff;
  cursor: pointer;
  z-index: 99;
}

.editorTabs {
  margin-left: 2%;
  width: 400px;
  background: #2f3129;
  transition: width 0.3s ease-in-out;
}

.expanded {
  width: calc(100% - 388px);
  padding-right: 15px;
}

.tabButtons {
  display: flex;
  justify-content: space-between;
}

.tabButtons > span {
  width: 33.33%;
  text-align: center;
  padding: 10px;
  color: #fff;
  cursor: pointer;
}

.activeTab {
  background: #272822;
}

.preview {
  width: calc(100vw - 787px);
  height: calc(100vh - 65px);
  padding: 15px;
  padding-right: 0px;
  background: #2f3129;
}

.preview iframe {
  width: 100%;
  height: 100%;
  border: 0;
  background: #fff;
  border-radius: 2px;
}

.lpForm {
  position: fixed;
  right: 0;
  width: 350px;
  height: 100vh;
  background: #fff;
  padding: 10px 20px;
  background: #2f3129;
}

.lpForm label {
  color: #fff;
  display: inline-block;
  margin-bottom: 5px;
}

.lpForm > div {
  margin-bottom: 20px;
}

.lpForm input,
.lpForm textarea,
.lpForm :global(.ant-select-selector) {
  color: #fff;
  background: #272822;
}

.lpForm :global(.ant-select-selection-item) {
  background: #2f3129;
}

.lpForm :global(.ant-select-selection-item svg) {
  color: #fff;
}

.lpForm .statusSelect :global(.ant-select-selector) {
  padding: 0;
}

.lpForm .statusSelect :global(.ant-select-selection-item) {
  padding-left: 11px;
}

.lpFormBtnsWrapper {
  display: flex;
  justify-content: space-between;
}

.createPrelanderCampaignBtn {
  width: 100%;
  margin-top: 40px;
}
