.addOrEditProduct :global(.ant-tabs) {
  overflow: visible;
}

.addOrEditProduct :global(.ant-tabs-bar) {
  margin-bottom: 30px;
}

.addOrEditProduct :global(.ant-tabs-ink-bar) {
  height: 6px;
  background-color: #2f6fb6;
}

.addOrEditProduct :global(.ant-tabs-tab),
.addOrEditProduct :global(.ant-tabs-tab-active) {
  font-size: 1.15em;
  font-weight: 500;
  padding: 12px 16px;
}

.titleAndCloseBtnWrapper {
  display: flex;
  justify-content: space-between;
}

.closeTabView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
  border-radius: 50%;
  border: none;
  background-color: rgb(219, 64, 64);
  color: #fff;
  cursor: pointer;
}

.closeTabView:hover {
  background-color: rgb(233, 92, 92);
}

.methodTypeSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 200px;
  height: 40px;
  border-radius: 4px;
  padding: 0rem 0.6rem;
  border: 1px solid #eeeeee;
  background-color: #fafafa;
  transition: all 0.2s;
  z-index: 10;
  margin-right: 0.5rem;
  margin-bottom: 10px;
  /* text-align: center; */
}

.methodTypeSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 200px;
  height: 40px;
  border-radius: 4px;
  padding: 0rem 0.6rem;
  border: 2px solid #8fc9ff;
  background-color: #fafafa;
  transition: all 0.2s;
  z-index: 10;
  margin-right: 0.5rem;
  margin-bottom: 10px;
  /* text-align: center; */
}

.methodTypeSelect:hover {
  /* background-color: #c8e5ff; */
  border: 1px solid #8fc9ff;
}
.methodTypeSelect > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.warningInterstitial {
  margin: 0;
  color: rgb(230, 74, 74);
  font-style: italic;
}
