.mainTxt {
  font-weight: bolder;
  font-size: 14px;
  display: block;
  margin-bottom: 7px;
}
.subTxt {
  color: #bcbccb;
  font-size: 8px;
}
