.collapseCol :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
  color: #1990ff;
}

.collapseCol :global(.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
  padding-top: 0;
}

.filterNameAndSaveFilterWrapperRow {
  justify-content: end;
}
