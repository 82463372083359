.customPixelContainer {
  display: flex;
}
.customPixelAdvanced {
  width: 30%;
}

.s2sHigher {
  min-width: 250px;
  width: 19%;
}

.s2sHigherHeader {
  display: flex;
  flex-direction: column;
  min-width: 250px;
}
.s2sSubHeader {
  display: flex;
  font-size: 0.8rem;
}

.s2sHigherForm {
  display: flex;
}

.s2sAdvanced {
  display: flex;
  flex-direction: row;
  min-width: 75px;
  align-items: center;
  width: 30%;
}

.s2sColWrapper {
  width: 70%;
}

.s2sCol {
  overflow: auto;
  display: flex;
  /* width: 14%; */
  /* width: 70%; */
}

.s2sColType :global(.ant-select-selection-item) {
  font-size: 0.8rem;
}
.s2sColType {
  width: 56%;
  min-width: 40px;
}

.s2sColValue {
  width: 44%;
  min-width: 70px;
}

.label {
  font-size: 0.9em;
  height: 20px;
}
