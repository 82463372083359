.nameAndPrivateFieldWrapper,
.privateAndImportExport,
.privateFieldWrapper :global(.ant-form-item-control-input-content) {
  /* .importExportWrapper,
.importExportWrapper > div,
.importBtnWrapper,
.exportBtnWrapper { */
  display: flex;
}

.nameAndPrivateFieldWrapper {
  align-items: flex-end;
}

.privateFieldWrapper {
  width: 100%;
}

.privateFieldWrapper :global(.ant-form-item-control-input-content) {
  justify-content: center;
}

.marginBotModal {
  margin-bottom: 20px;
}

.formItemWrapper4 {
  width: 22%;
}

.disabledPricesForm {
  cursor: not-allowed;
  opacity: 0.5;
  background: #ccc;
  color: rgba(0, 0, 0, 0.65);
  padding: 10px;
}

.disabledPricesForm div {
  pointer-events: none;
}

.disabledPricesForm :global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector),
.disabledPricesForm :global(.ant-btn-dashed),
.disabledPricesForm :global(.ant-btn) {
  background: rgb(241, 241, 241);
  color: rgba(0, 0, 0, 0.65);
}

.widthModal50 {
  width: 50%;
}
