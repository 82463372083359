.ant-layout-sider-collapsed .logo img {
  width: 100%;
}

.ant-layout-sider-collapsed .logo span {
  display: none;
}

.ant-layout-sider-zero-width-trigger {
  background-color: #012d6d;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  color: #fff;
}

.logo img {
  display: block;
  width: 50%;
  margin: 0 auto;
}

.logo span {
  white-space: nowrap;
}

.service-desk-link {
  padding-left: 24px;
  color: #a6b5cc;
  transition: color 0.4s;
}
.service-desk-link:hover {
  color: #fff;
}
.service-desk-link span {
  padding-left: 6px;
}
.ant-layout-sider-collapsed .service-desk-link {
  text-align: center;
  padding: 0;
  display: block;
  font-size: 20px;
}
.ant-layout-sider-collapsed .service-desk-link span {
  display: none;
}

.logo span {
  position: relative;
  top: 5px;
}

.custom-menu-item {
  display: block;
  width: 100%;
  height: 100%;
}

/* #nprogress .bar {
  background: #1890ff;
}

#nprogress .peg {
  box-shadow: 0 0 10px #1890ff, 0 0 5px #1890ff;
}

#nprogress .spinner-icon {
  border-top-color: #1890ff;
  border-left-color: #1890ff;
} */

.has-error {
  font-size: 10px;
}
.ant-layout-sider-children {
  overflow: auto;
}

.ant-layout-sider {
  background: #012d6d;
}

.ant-menu.ant-menu-dark {
  background: #012d6d;
}

.ant-menu.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #2e71b6;
  border-bottom: 1.5px solid #e0a64e;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #012d6d;
  box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.45);
}

.ant-layout-sider-trigger {
  background: #2964a9;
}
.ant-layout-sider-zero-width-trigger {
  top: 10px;
  z-index: 9999;
}
@media (max-width: 600px) {
  .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    display: none;
  }
}
.export-btn {
  margin: 0.8%;
}

/* GLOBAL STYLES: */
.divider {
  background: #f0f2f6;
  margin-top: 4%;
  width: 5000px; /* TODO: WTF??? */
  padding-left: 0;
  margin-left: -25px;
  padding: 5px;
  font-size: 1.4em;
}

h1 {
  font-size: 2em;
}

.modal-button {
  width: 100%;
  overflow: hidden;
}

.header {
  line-height: normal;
}
