.spanMarginBottom {
  margin-bottom: 5px;
  display: block;
}
.bgColor :global(.ant-select-selection-item-remove) {
  color: #007eff !important;
}
.bgColor :global(.ant-select-selection-item) {
  background-color: rgba(0, 126, 255, 0.08) !important;
  border: 1px solid rgba(0, 126, 255, 0.24) !important;
  color: #007eff !important;
}
.spanFontWeight500 :global(.ant-row .ant-col > span),
.spanFontWeight500 :global(.ant-row .ant-col > div > span) {
  font-weight: 500;
}
.bgColor :global(.ant-select-selector) {
  border: none !important;
}
:global(.ant-table-summary > tr > td) {
  background: #fbfbfb;
  border-top: 1px solid black;
}
.negative {
  background-color: #ff969647;
}
.mobileViewData {
  margin-bottom: 0.5rem;
}

.filtersWrapper :global(.ant-col) {
  margin-top: 20px;
}

.noProfitWrapper {
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.noProfitWrapper > span {
  margin-right: 8px;
}

.sourcesHeadingWrapper {
  display: flex;
  justify-content: space-between;
}

.sourcesHeadingWrapper :global(.ant-radio-group) {
  display: flex;
}

.sourcesHeadingWrapper :global(.ant-radio-button-wrapper) {
  width: 20px;
  height: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sourcesHeadingWrapper :global(.ant-radio-button-wrapper .ant-radio-button) {
  width: 20px;
  height: 20px;
}

.weekend :global(td:first-child) {
  font-weight: 900;
}
