.card :global(.ant-card-body) > div {
  height: 200px;
  overflow-y: scroll;
}
.card {
  border-radius: 10px;
}
.row {
  display: flex;
  margin-bottom: 20px;
}

.row :global(.ant-select-selector),
.row :global(.ant-picker),
.row :global(.ant-input-number),
.row :global(.ant-input) {
  border-radius: 10px !important;
}

.row :global(.ant-select),
.row :global(.ant-picker),
.row :global(.ant-input-number),
.row :global(.ant-input) {
  width: 100%;
}

.row > div {
  display: flex;
  margin-right: 30px;
}
.row > div:not(:last-child) {
  max-width: 300px;
  min-width: 200px;
}
.row * {
  align-self: center;
}
