.customTables :global(.ant-table-thead > tr > th),
.customTables :global(.ant-table-tbody > tr > td),
.customTables :global(.ant-table tfoot > tr > th),
.customTables :global(.ant-table tfoot > tr > td) {
  padding: 8px 8px;
}

.customTables :global(.ant-table) {
  border-radius: 15px;
}

.customTables :global(.ant-table-title) {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.customTables .greenTable :global(.ant-table-title h3) {
  color: #58a54f;
  margin: 0;
}
.customTables .greenTable :global(.ant-table-title) {
  border-bottom: 1px solid #58a54f;
}
.customTables .greenTable :global(.ant-table) {
  border: 1px solid #58a54f;
}
.customTables .greenTable :global(.ant-table .ant-table-title),
.customTables .greenTable :global(.ant-table .ant-table-thead) {
  background-color: #58a54f1a;
}

.customTables .redTable :global(.ant-table-title h3) {
  color: #d85c5c;
  margin: 0;
}
.customTables .redTable :global(.ant-table-title) {
  border-bottom: 1px solid #d85c5c;
}
.customTables .redTable :global(.ant-table) {
  border: 1px solid #d85c5c;
}
.customTables .redTable :global(.ant-table .ant-table-title),
.customTables .redTable :global(.ant-table .ant-table-thead) {
  background-color: #d85c5c1a;
}
.customTables .blueTable :global(.ant-table-title h3) {
  color: #1990ff;
  margin: 0;
}
.customTables .blueTable :global(.ant-table-title) {
  border-bottom: 1px solid #1990ff;
}
.customTables .blueTable :global(.ant-table) {
  border: 1px solid #1990ff;
}
.customTables .blueTable :global(.ant-table .ant-table-title),
.customTables .blueTable :global(.ant-table .ant-table-thead) {
  background-color: #1990ff1a;
}

.customTables :global(.ant-table-tbody .ant-table-row:last-child .ant-table-cell) {
  border-radius: 15px;
}

.customTables :global(.ant-table-tbody > tr.ant-table-row:hover > td) {
  background: transparent;
}

.customtables :global(.ant-table .ant-table-tbody) {
  background-color: white;
}
.customTables :global(.ant-table-thead > tr > th) {
  background: transparent;
}

.productsTooltip {
  max-width: 400px;
}
