.headerActivateAction:hover {
  color: #15a049;
  border-color: #15a049;
}

.headerPauseAction:hover {
  color: #eac62b;
  border-color: #eac62b;
}

.headerDeleteAction:hover {
  color: #f84747;
  border-color: #f84747;
}

.campaignsTable table thead th {
  font-size: 1.05em;
  background-color: #fff;
  color: #707070;
}

.campaignsTable table tbody {
  font-size: 0.9em;
  font-weight: 500;
  color: rgb(48, 48, 48);
}

.campaignsTable :global(.ant-table-row:nth-child(even)) {
  background-color: #fbfbfb;
}

.campaignsTable :global(.ant-table-footer) {
  background-color: #fff;
}

.statusColumn,
.status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaignsTable .campaignNameColumn {
  padding-left: 50px;
}

.status {
  color: #fff;
  text-transform: capitalize;
  width: 80px;
  position: relative;
  border-radius: 5px;
  margin: 10px 0;
}

.statusActive {
  background: #32b66b 0% 0% no-repeat padding-box;
  border: 1px solid #2e965c;
}

.statusPaused {
  background: #faae3b 0% 0% no-repeat padding-box;
  border: 1px solid #9b6e00;
}

.statusSuspended {
  background: #d95c5c 0% 0% no-repeat padding-box;
  border: 1px solid #a50d12;
}

.channelColumn,
.companyColumn {
  cursor: pointer;
}

.channelColumn,
.campaignUrlColumn {
  word-break: break-all;
}

.campaignUrlColumn > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.campaignUrlColumn > div > span {
  margin-left: 20px;
  font-size: 1.3em;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions > div {
  cursor: pointer;
  margin-right: 10px;
}

.actions div:last-child {
  margin-right: 0;
}

.actions :global(.ant-popover-inner-content) {
  padding: 5px 8px !important;
}
