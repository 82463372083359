.headingFields {
  display: flex;
  margin-bottom: 30px;
}

.headingFields > div {
  margin-right: 20px;
}

/* .searchInput {
  width: 250px;
} */

.widthModal50 {
  width: 50%;
}

.widthModal100 {
  width: 100%;
}

.macrosContent :global(.ant-divider-horizontal) {
  margin: 0;
}
.macrosContent span {
  cursor: pointer;
}
.macrosContent span:hover {
  font-weight: bold;
}

.externalURLTable table thead th {
  font-size: 1.05em;
  background-color: #fff;
  color: #707070;
}

.externalURLTable table tbody {
  font-size: 0.9em;
  font-weight: 500;
  color: rgb(48, 48, 48);
}

.externalURLTable :global(.ant-table-row:nth-child(even)) {
  background-color: #fbfbfb;
}

.externalURLTable :global(.ant-table-footer) {
  background-color: #fff;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions > div {
  cursor: pointer;
  margin-right: 10px;
}

.actions div:last-child {
  margin-right: 0;
}

.actions :global(.ant-popover-inner-content) {
  padding: 5px 8px !important;
}
