.landingPageOption {
  padding: 7px;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  cursor: pointer;
}

.landingPageOption:hover {
  width: 100% !important;
  background-color: rgb(235, 241, 255);
}

.eyePreviewImg {
  margin-right: 7px;
}

:global(.Select-value-label) .eyePreviewImg {
  margin-bottom: 3px;
}
