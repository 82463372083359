.spanMarginBottom {
  margin-bottom: 5px;
  display: block;
}

.checkedColumn {
  align-self: flex-end;
}

.btnCol {
  align-self: flex-end;
  margin-top: 1rem;
  margin-right: 1rem;
}

.paddingBetweenRows :global(.ant-col) {
  margin-bottom: 24px;
}

.titleContent {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  color: #43425d;
}

.titleContent h1 {
  margin: 0;
  margin-right: 1rem;
}

/* Actions table column style */

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions > div {
  cursor: pointer;
  margin-right: 10px;
}

.actions div:last-child {
  margin-right: 0;
}

.actions :global(.ant-popover-inner-content) {
  padding: 5px 8px !important;
}

/* Table style */

.notificationsTable {
  margin-top: 2rem;
}

.notificationsTable :global(.ant-table-row) {
  cursor: pointer;
}
