.ruleNumberInput :global(.ant-input-number) {
  width: 100%;
}

.mediaCampaignTabs :global(.ant-tabs) {
  overflow: visible;
}

.mediaCampaignTabs :global(.ant-tabs-bar) {
  margin-bottom: 30px;
}

.mediaCampaignTabs :global(.ant-tabs-ink-bar) {
  height: 6px;
  background-color: #2f6fb6;
}

.mediaCampaignTabs :global(.ant-tabs-tab),
.mediaCampaignTabs :global(.ant-tabs-tab-active) {
  color: #000;
  font-size: 1.15em;
  font-weight: 500;
  padding: 12px 16px;
}

.ruleDynamicFieldsWrapper,
.switchWithImg {
  display: flex;
}

.formWrapper {
  flex-wrap: wrap;
}

.formItemWrapper4 {
  width: 22%;
}

.formItemWrapper3 {
  width: 30%;
}

.formItemWrapper2 {
  width: 48%;
}

.minContentWidth {
  width: min-content;
}

.ruleStartSwitch :global(.ant-switch-inner),
.switchWithImg :global(.ant-switch-inner) {
  width: 25px;
}

.ruleDynamicFieldsWrapper {
  align-items: center;
  margin-bottom: 5px;
}

.ruleDynamicFieldsWrapper > div {
  margin-right: 10px;
}

.ruleDynamicFieldsWrapper > div:last-child {
  margin-right: 0;
}

.ruleHeading {
  display: flex;
  justify-content: space-between;
}

.ruleHeadingText,
.ruleAlertTextContent {
  font-size: 1.15em;
  font-weight: 700;
}

.ruleHeadingText,
.ruleDynamicFieldsWrapper {
  margin-bottom: 30px;
}

.ruleHeadingText span {
  font-weight: 400;
  color: #a2a2a2;
}

.ruleAlertTextContent {
  font-weight: 500;
}

.ruleAlertTextContent,
.ruleTextContent {
  font-size: 1.1em;
}

.ruleNumberInput {
  width: 120px;
}

.switchWithImg img {
  margin-left: 10px;
}

.ruleAddDynamicFields {
  width: 300px;
}

.formRemoveBottomMargin :global(.ant-form-item) {
  margin-bottom: 0px;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action {
  margin-right: 5px;
  cursor: pointer;
}

.action:last-child {
  margin-right: 0;
}
