.spanMarginBottom {
  margin-bottom: 5px;
  display: block;
}
.bgColor :global(.ant-select-selection-item-remove) {
  color: #007eff !important;
}
.bgColor :global(.ant-select-selection-item) {
  background-color: rgba(0, 126, 255, 0.08) !important;
  border: 1px solid rgba(0, 126, 255, 0.24) !important;
  color: #007eff !important;
}
.spanFontWeight500 :global(.ant-row .ant-col > span),
.spanFontWeight500 :global(.ant-row .ant-col > div > span) {
  font-weight: 500;
}
.bgColor :global(.ant-select-selector) {
  border: none !important;
}

.filtersWrapper :global(.ant-col) {
  margin-bottom: 20px;
}
