.verticalMenu {
  margin-right: 24px;
  background-color: #f0f3f8;
}

.liveCampaigns {
  display: flex;
  justify-content: space-between;
  color: #000000de;
  margin: 16px;
}

.liveCampaignsText {
  font-size: 1.1em;
  font-weight: 500;
  color: #000000de;
}

.searchAndFilter {
  margin: 16px;
}

.searchInput {
  margin-bottom: 15px;
}

.searchInput :global(.ant-input-affix-wrapper),
.searchAndFilter .filterSelect :global(.ant-select-selector) {
  border: none;
}

.searchInput :global(.ant-input-affix-wrapper),
.searchInput input,
.searchAndFilter .filterSelect :global(.ant-select-selector) {
  background-color: #e2e5ea;
}

.searchAndFilter .filterSelect {
  margin-bottom: 15px;
}

.searchAndFilter .filterSelect:last-child {
  margin-bottom: 0;
}

.filterSelect :global(.ant-select) {
  width: 100%;
}

.verticalMenu :global(.ant-table-wrapper) {
  width: 220px;
}

.verticalMenu colgroup col {
  width: 0 !important;
  min-width: 0 !important;
}

.verticalMenuShrink :global(.ant-table-wrapper) {
  width: 70px;
}

.verticalMenuShrink .nameInitials {
  margin-right: 0;
}

.verticalMenu td {
  background-color: #f0f3f8;
  cursor: pointer;
}

.verticalMenu :global(.ant-table-tbody > tr.ant-table-row:hover > td) {
  background-color: #e2e5ea;
}

.campaignsTable :global(.ant-table-pagination) {
  width: 100%;
  display: flex;
  justify-content: center;
}
