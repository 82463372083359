.custom-css-virtualized-select .Select-control,
.custom-css-virtualized-select .Select-input {
  height: 29.5px;
}
.custom-css-virtualized-select .Select-value {
  margin-top: 3px;
}

.custom-css-virtualized-select .Select-control {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  word-break: break-all;
}
.custom-css-virtualized-select .Select-control:hover {
  border-color: #40a9ff;
}
.custom-css-virtualized-select > .Select-control:focus-within {
  border-color: #40a9ff;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.custom-css-virtualized-select .Select-placeholder {
  color: #bfbfbf;
}
.custom-css-virtualized-select .Select-input > input {
  line-height: 1.5715;
  padding: 4px 0px 4px 0px;
}
.custom-css-virtualized-select .Select-clear {
  font-size: 11px;
  background-color: #bfbfbf;
  color: white;
  border-radius: 100%;
  display: block;
  width: 13px;
  height: 12px;
}
.Select.is-disabled > .Select-control {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.Select.is-disabled > .Select-control:hover {
  border-color: #d9d9d9;
}
.custom-css-for-removing-indicators .Select-clear-zone {
  display: none;
}
.custom-css-for-removing-indicators .Select-arrow-zone {
  display: none;
}
.VirtualizedSelectOption {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Select-menu-outer {
  z-index: 3;
}
