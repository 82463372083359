.question {
  padding: 12px 0px;
  border-bottom: 1px solid #eeeeee;
}

.question:last-child {
  border-bottom: none;
  padding-bottom: 0px;
}

.question:first-child {
  padding-top: 0px;
}

.question textarea {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 10px;
}

.question textarea:focus {
  outline: 1px solid #4d4f5c;
}

.card {
  border-radius: 10px;
  border: 1px solid #eeeeee;
}

.card :global(.ant-card-head) {
  background-color: #fafafa;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.years {
  border: none;
  border-radius: 10px;
}

.panel {
  border-radius: 0px !important;
  border: 1px solid #eeeeee;
}

.panel :global(.ant-collapse-content-active) {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.panel:first-child {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.panel:last-child {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.panel :global(.ant-collapse-header) {
  border-radius: 0px !important;
}

.table :global(.ant-table-tbody > tr > td) {
  padding: 8px 8px;
}

.table :global(.ant-table-thead > tr > th) {
  padding: 8px 8px;
  background: none;
}

.table
  :global(
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before
  ) {
  width: 0px;
}

.table :global(.ant-table-row:last-child) {
  font-weight: 500;
}

.buttonLeft {
  margin: 0px 0px 0px auto;
  display: block;
}
