.lists :global(.ant-tabs-bar) {
  margin-bottom: 30px;
}

.lists :global(.ant-tabs-ink-bar) {
  height: 6px;
  background-color: #2f6fb6;
}

.lists :global(.ant-tabs-tab),
.lists :global(.ant-tabs-tab-active) {
  color: #000;
  font-size: 1.15em;
  font-weight: 500;
  padding: 12px 16px;
}

.createListBtn {
  margin-bottom: 30px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.actions > div {
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
}

.actions > div:first-child {
  margin-right: 12px;
}

.actions > div:last-child {
  margin-right: 0;
}

.actions :global(.ant-popover-inner-content) {
  padding: 5px 8px !important;
}

.addOrEditListModal :global(.ant-modal-header .ant-modal-title) {
  font-size: 1.4em;
}

.modalFormWrapper,
.selectImgWrapper {
  display: flex;
}

.addOrEditListModal :global(.ant-select-selection-item-content),
.addOrEditListModal :global(.ant-select-selection-item-content) {
  display: flex !important;
}

.addOrEditListModal :global(.ant-space-horizontal) {
  align-items: flex-start;
}

.modalFormWrapper :global(.ant-select-selection-search-input) {
  margin-left: 0 !important;
}

.selectDropdownWithImg :global(.ant-select-item-option-content) {
  display: flex !important;
}

.hideSelectDropdown {
  display: none;
}

.modalFormWrapper {
  flex-direction: column;
}

.widthModal50 {
  width: 50%;
}

.marginBotModal {
  margin-bottom: 20px;
}

.selectImgWrapper {
  align-items: center;
  margin-right: 5px;
}

.selectImgWrapper img {
  max-width: 16px;
  max-height: 16px;
}

.imageUploaderModal :global(.fileContainer) {
  margin: 0;
  border: 1px solid #d9d9d9;
  box-shadow: none;
  border-radius: 5px;
}

.imagePreviewModal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.imagePreviewModal img {
  width: 100px;
}

.listsTable table thead th {
  font-size: 1.05em;
  background-color: #fff;
  color: #707070;
}

.listsTable table tbody {
  font-size: 0.9rem;
  font-weight: 600;
  color: #000;
}

.listsTable :global(.ant-table-row:nth-child(even)) {
  background-color: #fbfbfb;
}

.creatorAndDateCreated {
  font-size: 0.9em;
  font-weight: 500;
  color: #00000099;
}

/* .dynamicFormFields {
  display: flex;
  justify-content: space-between;
}

.dynamicCountryField {
  width: 65%;
}

.dynamicCountryField :global(.ant-select-selection-item) {
  display: flex;
}

.dynamicPriceField {
  width: 30%;
}

.dynamicPriceField :global(.ant-input-number) {
  width: 100%;
}

.removeDynamicField {
  margin-top: 6px;
} */

.AddOrEditTargetingListModal :global(.ant-modal) {
  width: 90% !important;
}

.AddOrEditTargetingListModal :global(.ant-modal-header .ant-modal-title) {
  font-size: 1.4em;
}

* :global(.ant-table-footer) {
  padding: 0;
}

.headingActions {
  display: flex;
  margin-bottom: 30px;
}

.headingActions > div {
  margin-right: 20px;
}

.assignedFilter {
  width: 250px;
}

.nameAndCheckboxFieldsWrapper,
.checkboxFiledsWrapper {
  display: flex;
}

.nameAndCheckboxFieldsWrapper {
  align-items: flex-end;
  justify-content: center;
}

.checkboxFiledsWrapper {
  justify-content: space-around;
}

.removeImageButton {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

:global(.ant-select-selection-item-content) .listedTooltip {
  display: none;
}
