.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action {
  margin-right: 5px;
  cursor: pointer;
}

.action:last-child {
  margin-right: 0;
}
