.selectDropdownWithImg :global(.ant-select-item-option-content) {
  display: flex !important;
}

.filterRow {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
}

.filterLabel {
  font-size: 1.1em;
  margin-bottom: 5px;
}

.filterRangePicker,
.filterSelect {
  width: 100%;
}

.filterCheckbox {
  margin-bottom: 5px;
}
