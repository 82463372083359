.spanMarginBottom {
  margin-bottom: 5px;
  display: block;
}
.spanFontWeight500 :global(.ant-row .ant-col > span),
.spanFontWeight500 :global(.ant-row .ant-col > div > span) {
  font-weight: 500;
}
:global(.ant-table-summary > tr > td) {
  background: #fbfbfb;
  border-top: 1px solid black;
}
.negative {
  background-color: #ff969647;
}
