.switchWrapper {
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switchWrapper > span {
  margin-right: 8px;
}

.switchLabel {
  white-space: nowrap;
}
