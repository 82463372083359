.addOrEditProduct :global(.ant-tabs) {
  overflow: visible;
}

.addOrEditProduct :global(.ant-tabs-bar) {
  margin-bottom: 30px;
}

.addOrEditProduct :global(.ant-tabs-ink-bar) {
  height: 6px;
  background-color: #2f6fb6;
}

.addOrEditProduct :global(.ant-tabs-tab),
.addOrEditProduct :global(.ant-tabs-tab-active) {
  font-size: 1.15em;
  font-weight: 500;
  padding: 12px 16px;
}

.titleAndCloseBtnWrapper {
  display: flex;
  justify-content: space-between;
}

.closeTabView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
  border-radius: 50%;
  border: none;
  background-color: rgb(219, 64, 64);
  color: #fff;
  cursor: pointer;
}

.closeTabView:hover {
  background-color: rgb(233, 92, 92);
}

.activeSwitchRow {
  justify-content: flex-end;
}

.activeSwitchRow .activeSwitchFormItem {
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.activeSwitchFormItem :global(.ant-form-item-label) {
  padding: 0;
  margin-right: 10px;
}
