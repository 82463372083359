.moveRuleOuterWrapperCol {
  padding-bottom: 15px;
}

.singleMoveRuleOptionOuterWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.moveRule {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  font-size: 0.7em;
  font-weight: 500;
  text-align: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  cursor: pointer;
}
