.incluceExcludeSelectWrapper {
  position: relative;
}

.includeExcludeBox {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 5px;
  transform: translateY(50%);
  z-index: 2;
}

.includeBox {
  background-color: #58a54f;
}

.excludeBox {
  background-color: #e95c5c;
}
