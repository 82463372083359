.mediaCampaignTabs,
.formItemWrapper1,
.ruleNumberInput :global(.ant-input-number),
.pricesRadioAndSelectWrapper,
.targetingRadioAndSelectWrapper,
.activityInfoWrapper {
  width: 100%;
}

.mediaCampaignTabs :global(.ant-tabs) {
  overflow: visible;
}

.mediaCampaignTabs :global(.ant-tabs-bar) {
  margin-bottom: 30px;
}

.mediaCampaignTabs :global(.ant-tabs-ink-bar) {
  height: 6px;
  background-color: #2f6fb6;
}

.mediaCampaignTabs :global(.ant-tabs-tab),
.mediaCampaignTabs :global(.ant-tabs-tab-active) {
  color: #000;
  font-size: 1.15em;
  font-weight: 500;
  padding: 12px 16px;
}

.mediaCampaignTabs :global(.ant-input),
.mediaCampaignTabs :global(.ant-select-selection-item),
.mediaCampaignTabs :global(.ant-select-selection-placeholder) {
  color: rgba(0, 0, 0, 0.8);
}

.mediaCampaignTabs :global(span.ant-radio + *) {
  font-size: 1rem;
}

/* .mediaCampaignTabs :global(.ant-checkbox-inner),
.mediaCampaignTabs :global(.ant-radio-inner) {
  width: 19px;
  height: 19px;
} */

.tabsHeadingWrapper,
.headerWithClear {
  display: flex;
  justify-content: space-between;
}

.tabsDeleteAndSaveButton,
.formWrapper,
.prefixAndChannelWrapper,
.campaignTypeAndRevshare,
.s2sHeading,
.s2sDynamicFieldsWrapper,
.filtersRowWrapper,
.filtersRowFields,
.ruleDynamicFieldsWrapper,
.switchWithImg,
.targetingRadioAndSelectWrapper,
.pricesRadioAndSelectWrapper {
  display: flex;
}

.mergedCols {
  display: flex;
}
.mergedCols :global(.ant-col):first-child {
  padding-right: 0 !important;
}
.mergedCols :global(.ant-col):nth-child(2) {
  padding-left: 0 !important;
}

.formWrapper {
  flex-wrap: wrap;
}

.formItemWrapper4 {
  width: 22%;
}

.formItemWrapper3 {
  width: 30%;
}

.formItemWrapper2 {
  width: 48%;
}

/* .formItemWrapper1 {
  width: 100%;
} */

.formItemMarginRight {
  margin-right: 3.99999%;
}

.formItemLabel,
.mediaCampaignTabs :global(.ant-checkbox-wrapper),
.mediaCampaignTabs :global(.ant-radio-wrapper) {
  font-size: 1.1em;
  color: rgba(0, 0, 0, 0.8);
}

.mediaCampaignTabs :global(.ant-radio-wrapper) {
  margin-right: 20px;
}

.prefixAndChannelWrapper > div:first-child {
  width: 35%;
}

.prefixAndChannelWrapper > div:last-child {
  width: 65%;
}

.campaignTypeAndRevshare > div:first-child {
  width: 65%;
}

.campaignTypeAndRevshare > div:last-child {
  width: 35%;
}

.s2sHeading {
  border-bottom: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
}

.s2sHeading {
  padding: 10px 0;
}

.s2sDynamicFieldsWrapper {
  margin: 10px 0;
}

.s2sHeading > div:first-child,
.s2sHeading > div:last-child,
.s2sDynamicFieldsWrapper > div:nth-child(2),
.s2sDynamicFieldsWrapper > div:last-child {
  width: 4%;
}

.s2sHeading > div:nth-child(2),
.s2sDynamicFieldsWrapper > div:nth-child(3) {
  width: 26%;
  padding-right: 20px;
}

.s2sHeading > div:nth-child(3),
.s2sDynamicFieldsWrapper > div:nth-child(4) {
  width: 66%;
}

.s2sDynamicFieldsWrapper :global(.ant-radio-wrapper) {
  margin: 0;
}

/* .s2sHeading > div {
  padding: 5px 0 5px 11px;
} */
/* .s2sHeading > div,
.s2sDynamicField {
  width: 67%;
  margin: 1% 1% 1% 0%;
}

.s2sHeading > div:first-child {
  width: 24%;
  margin-left: 3%;
}

.s2sDynamicFieldsWrapper > div:nth-child(3) {
  width: 27%;
  margin: 1% 1% 1% 0%;
} */

.s2sDynamicFieldsWrapper :global(.ant-form-item) {
  margin-bottom: 0;
}

.s2sDynamicFieldsWrapper .s2sDynamicField :global(.ant-select-selector),
.s2sDynamicField :global(.ant-input) {
  border-radius: 0;
}

.s2sRadio {
  display: flex;
  align-items: center;
}

.s2sRemoveDynamicFields {
  display: flex;
  align-items: center;
  justify-content: center;
}

.s2sAddDynamicFields,
.pricesGlobalList {
  width: 160px;
}

.filtersRowWrapper {
  flex-direction: column;
  margin-bottom: 30px;
}

.filtersRowWrapper:last-child {
  margin-bottom: 0px;
}

.filtersRowFields {
  align-items: baseline;
  margin-bottom: 5px;
}

.minContentWidth {
  width: min-content;
}

.ruleStartSwitch :global(.ant-switch-inner),
.switchWithImg :global(.ant-switch-inner) {
  width: 25px;
}

.ruleDynamicFieldsWrapper {
  align-items: center;
  margin-bottom: 5px;
}

.filtersRowFields > div {
  margin-right: 25px;
}

.ruleDynamicFieldsWrapper > div {
  margin-right: 10px;
}

.filtersRowFields > div:last-child,
.ruleDynamicFieldsWrapper > div:last-child {
  margin-right: 0;
}

.filtersName {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  font-weight: 700;
  color: #7c7b98;
  width: 140px;
}

.filtersDescription {
  color: #7c7b98;
  font-weight: 400;
}

.ruleHeading {
  display: flex;
  justify-content: space-between;
}

.ruleHeadingText,
.ruleAlertTextContent {
  font-size: 1.15em;
  font-weight: 700;
}

.ruleHeadingText,
.ruleDynamicFieldsWrapper {
  margin-bottom: 30px;
}

.ruleHeadingText span {
  font-weight: 400;
  color: #a2a2a2;
}

.ruleAlertTextContent {
  font-weight: 500;
}

.ruleAlertTextContent,
.ruleTextContent {
  font-size: 1.1em;
}

.ruleNumberInput {
  width: 120px;
}

/* .ruleNumberInput :global(.ant-input-number) {
  width: 100%;
} */

.switchWithImg img {
  margin-left: 10px;
}

.ruleAddDynamicFields {
  width: 300px;
}

.targetingRadioAndSelectWrapper > div:first-child,
.pricesRadioAndSelectWrapper > div:first-child {
  margin-right: 20px;
}

.targetingRadioAndSelectWrapper,
.pricesRadioAndSelectWrapper {
  align-items: flex-end;
}

.importExportAndClearWrapper,
.importAndClearWrapper {
  display: flex;
}

.importExportAndClearWrapper > button,
.importAndClearWrapper > button {
  margin: 2px 0 0 20px;
}

/* .targetingRadioAndSelectWrapper {
  width: 100%;
} */

.customRadio :global(.ant-form-item) {
  margin-bottom: 0;
}

/* .customRadio :global(.ant-radio-inner::after) {
  width: 12px;
  height: 12px;
  top: 2px;
  left: 2px;
} */

.note {
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 24px;
  color: #b9b9b9;
}

.formRemoveBottomMargin :global(.ant-form-item) {
  margin-bottom: 0px;
}

.closeTabView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
  border-radius: 50%;
  margin: 0 10px 0 20px;
  border: none;
  background-color: rgb(219, 64, 64);
  color: #fff;
  cursor: pointer;
}

.btnsUnderDividerWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btnMarginRight {
  margin-right: 20px;
}

.marginTop {
  margin-top: 20px;
}

.deleteBtn {
  background-color: rgb(219, 64, 64);
  border: none;
}

.closeTabView:hover,
.deleteBtn:hover {
  background-color: rgb(233, 92, 92);
}

.radioBtnGroup {
  display: flex;
  margin-left: 10px;
}

/* .radioBtnGroup > label:last-child {
  margin-left: 20px;
} */

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action {
  margin-right: 5px;
  cursor: pointer;
}

.action:last-child {
  margin-right: 0;
}

.copyCampInfoButton {
  height: 40px;
  width: 43px;
  position: absolute;
  cursor: pointer;
  background: #1890ff;
  border: none;
  outline: none;
}
.copyCampInfoButton:hover {
  background: #40a9ff;
}
.copyCampInfoButton:active {
  background: #096dd9;
}

.activityTab :global(.ant-table-pagination) {
  margin-bottom: 0;
}

.activitiesDate {
  font-size: 1.1em;
  font-weight: 600;
  color: #4d4f5c;
  margin-bottom: 20px;
}

.activityRowWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 20px;
  border: 1px solid #eeeeee;
  font-size: 1.05em;
}

.activityRowMessage {
  white-space: pre-line;
}

.activityRowInfo {
  white-space: nowrap;
  margin-left: 20px;
}

.activityInfoUser {
  color: #1990ff;
}

/*
.activityImgAndVertLineWrapper {
  position: relative;
}

.activityImgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  border: 1px solid #70707099;
  border-radius: 50%;
  margin: 0 20px;
  background: #fff;
}

.activityImgWrapper > img {
  width: 20px;
  height: 20px;
}

.activityVerticalLine {
  position: absolute;
  left: 50%;
  height: 100%;
  border: 1px dashed #70707099;
}

/* .activityInfoWrapper {
  width: 100%;
} */
/*
.activityDateAndTime,
.activityAuthor {
  font-weight: 500;
  color: #70707099;
} */

@media (min-width: 1400px) {
  .tabsDeleteAndSaveButton > button:first-child,
  .btnsUnderDividerWrapper button {
    width: 160px;
  }
}

.mediaCampaignTabs :global(.ant-tabs > .ant-tabs-nav),
.mediaCampaignTabs :global(.ant-tabs > div > .ant-tabs-nav) {
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: white;
  height: 50px;
}
